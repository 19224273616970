@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


@layer base {
    ::selection {
        @apply bg-green-100 text-green-900;
    }

    html {
        @apply text-sm text-gray-900;
        font-feature-settings: 'ss01', 'ss04';
    }
    
    body {
        @apply overflow-hidden h-full;
    }

    h3 {
        @apply mt-10 mb-2 font-bold text-base uppercase text-brown-600;
    }

    kbd {
        @apply relative inline-block px-1 py-[2px] -top-px bg-[#EEE] border border-[#B4B4B4] rounded-[3px] font-bold text-[0.85rem] text-[#333] leading-[1] whitespace-nowrap;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset;
    }

    svg {
        @apply inline;
    }
}


.tippy-svg-arrow {
    fill: inherit !important;
}
