$yellowL2: #FEF1D5;
$yellowL2T: #FBEAC5;
$yellowL: #FADA9D;
$yellow: #FBCC70;
$yellowD: #EDAE50;

$blueL: #BACFCA;
$blueLT: #A2BEBE;
$blue: #6DA0A4;
$blueD: #56858B;

$red: #E15A54;
$redD: #B84138;

$nightL: #A5A7D0;
$night: #7375A5;

$apricotL: #F8D5AC;
$apricotLT: #F7D1A5;
$apricot: #FDC78B;
$apricotD: #E49668;

$brownD: #944920;

$gray1: #F7F7F7;
$gray2: #E5E5E5;
$gray3: #AFAFAF;
$gray4: #777777;
$gray5: #4B4B4B;

$transition: 0.2s cubic-bezier(.25,.8,.25,1);



/*
*   TOOLBAR
*/
@mixin interacttool($toolcolor) {
	&:hover {
		svg {
			color: $toolcolor;
		}
	}

	&.active {
		background-color: $toolcolor;

		svg {
			color: white;
		}
	}
}

.toolbar-item {
	svg {
		height: 1.1rem;
		width: 1.1rem;
		margin: -0.15rem auto 0;
	}

	&.pan, &.select, &.toolbar-expand {
		@include interacttool($brownD);
	}

	&.input {
		@include interacttool($night);
	}

	&.output, &.addToProgram, &.notifyPerm {
		@include interacttool($red);
	}

	&.botText, &.botMedia, &.botIntMedia {
		@include interacttool($yellowD);
	}

	&.userButton, &.userList, &.userText, &.userSlider, &.userDate, &.userTime, &.pickProgram, &.pickQuest {
		@include interacttool($blue);
	}

	&.comment {
		@include interacttool($apricotD);
	}
}


/*
*   FLOW NODES
*/
@mixin flownode($lightcolor, $medcolor, $darkcolor: $medcolor, $lighttranscolor: $lightcolor) {
	border-color: $medcolor;
	background-color: $medcolor;

	::selection {
		background: $darkcolor;
		color: white;
	}

	.react-flow__handle {
		background: $medcolor;
	}

	.header {
		background-color: $medcolor;
	}

	input, .editText, .select, .option, .check {
		background-color: $lightcolor;

		&:hover, &:focus, &.focus {
			background-color: $lighttranscolor;
		}
	}

	.options {
		background-color: $lightcolor;
		border-color: $medcolor;
	}

	.btn-external {
		background-color: $darkcolor;

		&:hover {
			background-color: $medcolor;
		}
	}

	&.selectable:hover, &.selected, &.selectable:focus-within {
		border-color: $darkcolor;

		.react-flow__handle {
			background: $darkcolor;
		}
	}
}


.react-flow__node {
	width: 250px;
	border-width: 2px !important;
	border-radius: 0.5rem;
	background: white;
	font-size: 0.75rem;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	transition: box-shadow $transition;

	&:hover, &.selected {
		.btn-header {
			display: block;
		}
	}

	.react-flow__handle {
		width: 3rem;
		height: 0.5rem;
		border: none;
		border-radius: 0;
		transition: height $transition, top $transition, bottom $transition;
		z-index: -10;

		&:hover {
			height: 1rem;
		}

		&.react-flow__handle-top {
			top: -0.5rem;
			border-top-left-radius: 1rem;
			border-top-right-radius: 1rem;

			&:hover {
				top: -1rem;
				border-top-left-radius: 0.5rem;
				border-top-right-radius: 0.5rem;
			}
		}

		&.react-flow__handle-bottom {
			bottom: -0.5rem;
			border-bottom-left-radius: 1rem;
			border-bottom-right-radius: 1rem;

			&:hover {
				bottom: -1rem;
				border-bottom-left-radius: 0.5rem;
				border-bottom-right-radius: 0.5rem;
			}
		}
	}

	&.react-flow__node-input, &.react-flow__node-output {
		width: 250px;
		padding: 0.5rem 0;
		border-width: 2px;
		color: white;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 1rem;
	}

	&.react-flow__node-input {
		border-color: $nightL;
		background-color: $nightL;

		.react-flow__handle {
			background: $nightL;
		}

		&.selectable:hover, &.selected {
			border-color: $night;

			.react-flow__handle {
				background: $night;
			}
		}
	}

	&.react-flow__node-output {
		border-color: $red;
		background-color: $red;

		.react-flow__handle {
			background: $red;
		}

		&.selectable:hover, &.selected {
			border-color: $redD;

			.react-flow__handle {
				background: $redD;
			}
		}
	}

	&.react-flow__node-addToProgram, &.react-flow__node-notifyPerm {
		@include flownode(white, white, $red);
		padding: 0;
		border-color: $gray3;

		.react-flow__handle {
			background: $gray3;
		}

		.select {
			border: 1px $gray3 solid;
		}

		.options {
			border-color: $gray3;
		}

		.option:hover {
			background-color: $gray2;
		}
	}

	&.react-flow__node-userButton, &.react-flow__node-userList, &.react-flow__node-userText, &.react-flow__node-userSlider, &.react-flow__node-userDate, &.react-flow__node-userTime, &.react-flow__node-pickProgram, &.react-flow__node-pickQuest {
		@include flownode($blueL, $blue, $blueD, $blueLT);

		.header {
			color: white;

			.btn-header svg {
				fill: white;
			}
		}

		.select, .option {
			color: black;
		}
	}

	&.react-flow__node-botText, &.react-flow__node-botMedia, &.react-flow__node-botIntMedia, &.react-flow__node-botVideo {
		@include flownode($yellowL2, $yellowL, $yellowD, $yellowL2T);
	}

	&.react-flow__node-comment {
		@include flownode($apricotL, $apricot, $apricotD, $apricotLT);
	}
}


/*
*   FLOW EDGES
*/
.react-flow__edge {
	&:hover {
		cursor: pointer;

		.react-flow__edge-path {
			stroke-width: 5;
		}
	}
}


/*
*   FLOW CHROME
*/
.react-flow__minimap-mask {
	fill: #FFFCF3AA;
}
